// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// hearttoheart-test
/* const firebaseConfig = {
  apiKey: "AIzaSyCm-rDSl5elAKlHNchHZvbL0anenXheyxw",
  authDomain: "hearttoheart-test.firebaseapp.com",
  projectId: "hearttoheart-test",
  storageBucket: "hearttoheart-test.appspot.com",
  messagingSenderId: "471858427771",
  appId: "1:471858427771:web:32bbcb2af6d7f9444a6fde",
  measurementId: "G-NN5DHS7DPM"
}; */

// hearttoheart-production
const firebaseConfig = {
  apiKey: "AIzaSyB8Bf5JyiJfC3Cj2_IpBrLvmVOsXGgy8Sc",
  authDomain: "hearttoheart-production.firebaseapp.com",
  projectId: "hearttoheart-production",
  storageBucket: "hearttoheart-production.appspot.com",
  messagingSenderId: "489327905686",
  appId: "1:489327905686:web:bf0cf27bd82c0a0caf2bb7",
  measurementId: "G-ZCKK2X8ZJP"
};


// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);