import React from "react";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { setDoc, doc, addDoc, collection } from "firebase/firestore";
import { db } from "../firebase-config";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Alert, Button, Card, TextField} from "@mui/material";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FormatColorResetOutlined } from "@mui/icons-material";

function ShareStory() {
    const [postText, setPostText] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [submissionSuccess, setSubmissionSuccess] = useState(false);
    const [navigateUrl, setNavigateUrl] = useState("");
    const [step, setStep] = useState(0);
    const [submitOpen, setSubmitOpen] = useState(false);
    const [rError, setRError] = useState(false);
    const [recaptchaVerified, setRecaptchaVerified] = useState(false)
    const recaptchaVerification = useCallback(() => {
        setRecaptchaVerified(true);
      },
      [setRecaptchaVerified]
    );
    const { uniqueNamesGenerator, adjectives, colors, animals } = require('unique-names-generator');
    const shortName = uniqueNamesGenerator({
        dictionaries: [adjectives, colors, animals], // colors can be omitted here as not used
        separator: '-',
        length: 3
    });
    const rUniqueId = uniqueNamesGenerator({
        dictionaries: [adjectives, colors, animals], // colors can be omitted here as not used
        separator: '-',
        length: 3
    });
    let navigate = useNavigate();

    const response = [];

    const createStory = async () => {
        setSubmitting(true)
        setDoc(doc(db, "stories", shortName), {
            postText,
            rUniqueId
        })
            .then(() => {
                setDoc(doc(db, "responses", rUniqueId), {
                    response,
                    postText
                })
                    .then(() => {
                        setSubmitting(false)
                        setSubmissionSuccess(true)
                        setTimeout(() => {
                            navigate("/from/" + shortName)
                        }, 2000)
                    })
                    .catch(() => {
                        setSubmitting(false)
                        alert('Sorry, something went wrong. Please try again')
                    })
            })
            .catch(() => {
                setSubmitting(false)
                alert('Sorry, something went wrong. Please try again')
            })

    }


    function checkSubmit() {
        if (postText === "") {
            setSubmitOpen(true);
        }
        else if(recaptchaVerified===false) {
            setRError(true);
        }
        else {
            createStory();
        }
    }
    if (step === 1) {
        setTimeout(() => {
            navigate(navigateUrl)
        }, 1000)
        return (
            <Alert severity="success">You have successfully submitted your story! Redirecting...</Alert>
        );
    }
    return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>

    <div>

        <div>

            <Card class="user-input input-card">
                <div class="stextfield">
                {submitOpen?<Alert severity="error">You can't submit an empty story!</Alert>:<div></div>}
                {rError?<Alert severity="error">Error: You've been blocked by our spam prevention system. Try refreshing the page, but make sure to copy what you've written first so that you don't lose it!</Alert>:<div></div>}
                {submissionSuccess &&
                    <Alert severity="success">Success! Redirecting...</Alert>
                }
                {/* <Card className="input-card"> */}

                <TextField
                    className="story-input"
                    id="outlined-multiline-static"
                    multiline
                    placeholder="Share your perspective of what happened."
                    rows={20}
                    sx={{backgroundColor: "white"}}
                    onChange={(event) => {
                        setPostText(event.target.value);
                        setSubmitOpen(false)
                    }}
                />
               </div>
               <GoogleReCaptcha
          onVerify={recaptchaVerification}
        />
                <div class="sbutton">
            <Button
                className="submit-button"
                variant="contained"
                onClick={() => {
                    checkSubmit();
                }}
            >
                {submitting?
                    <div>
                        Sending...
                    </div>
                :
                    <div>
                        Submit
                    </div>
                }
            </Button>
            </div>

            </Card>

            
        </div>

    </div>
    </GoogleReCaptchaProvider>
);

}
export default ShareStory;