import React, { useEffect, useState } from "react";
import { getDocs, collection, doc, getDoc, arrayUnion, updateDoc, query, where, DocumentSnapshot } from 'firebase/firestore';
import { db } from '../firebase-config';
import { Card, Divider, CircularProgress, Button, Box, Snackbar, TextField, Alert } from '@mui/material'
import {ResponseBox} from './ResponseBox'

function From() {

    const url = window.location.href
    const [story, setStory] = useState("");
    const [response, setResponse] = useState("");
    const [rUniqueId, setRUniqueId] = useState("");
    const uniqueId = url.split('/')[4];
    const website = url.split('/')[2];
    const [step, setStep] = useState(0);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [exist, setExist] = useState(false);
    const [edit, setEdit] = useState(false);
    const [postText, setPostText] = useState("");
    const [submitOpen, setSubmitOpen] = useState(false);
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [rCode, setRCode] = useState("");
    const [rReply, setRReply] = useState("");
    // const [counter, setCounter] = useState(0);
    function clickEdit() {
        setEdit(true);
    }

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {

        const getStory = async () => {
            const snap = await getDoc(doc(db, 'stories', uniqueId))
            if (snap.exists()) {
                const data = [];
                data.push(snap.data());
                setStory(data[0].postText);
                setPostText(data[0].postText);
                setRUniqueId(data[0].rUniqueId);
                setLoading(true);
                setExist(true);
                // console.log(data[0].rUniqueId);

            }
            else {
                console.log("No such document");
                setLoading(true);
            }
        }
        getStory();
        setStep(1);

        /* const getReply = async () => {
            const rQuerySnapshot = await getDoc(doc(db, 'responses', rUniqueId));
            const rReply = rQuerySnapshot.data().reply
            setRReply(rReply);
        }
        getReply(); */
    }, [])
    function submitEdit(e) {
        e.preventDefault()
        if (postText === "") {
            setSubmitOpen(true);
        }
        else if (postText===story) {
            setEdit(false)
            setSubmitOpen(false);
        }
        else {
            setLoadingEdit(true)
            const updateStory = async () => {
                updateDoc(doc(db, "stories", uniqueId), {
                    postText
                })
                    .then(() => {
                        updateDoc(doc(db, "responses", rUniqueId), {
                            postText
                        })
                            .then(() => {
                                setLoadingEdit(false)
                                setEdit(false);
                                setSubmitOpen(false);
                                setStory(postText)
                            })
                            .catch(() => {
                                setLoadingEdit(false)
                                alert('Sorry, something went wrong. Please try again.')
                            })
                    })
                    .catch(() => {
                        setLoadingEdit(false)
                        alert('Sorry, something went wrong. Please try again.')
                    })
            };
            updateStory();
        }
    }
    const toLink = "http://" + website + "/to/" + rUniqueId;

    useEffect(() => {

        if (step === 1 && rUniqueId) {


            getResponse();
        }
    }, [step, rUniqueId])
    const getResponse = async () => {
        console.log(rUniqueId);
        const rQuerySnapshot = await getDoc(doc(db, 'responses', rUniqueId));
        const rdata = rQuerySnapshot.data().response
        const rcode = rQuerySnapshot.data().rCode
        setRCode(rcode);
        //console.log(rdata[0]);
        setResponse(rdata);
        //console.log(response);
    }

    const generateStory = () => {
        return (
            <div>
                {story}

                <Button className="inline-button utility-button" variant="contained" onClick={() => { clickEdit(); }}
                >Edit</Button> </div>

        );
    }
    const generateEdit = () => {
        return (
            <div>
                {submitOpen &&
                    <Alert className="alerts" severity="error">
                        You can't have an empty story!
                    </Alert>
                }
                <TextField
                    className="centered-content story-input"
                    id="outlined-multiline-static"
                    multiline
                    defaultValue={story}
                    rows={3}
                    onChange={(event) => {
                        setPostText(event.target.value);
                        setSubmitOpen(false)
                    }}
                />
                <div className="centered-content editable-value-buttons">
                    <Button
                        className="editable-value-buttons inline-button cancel-button"
                        variant="contained"
                        onClick={() => {
                            setEdit(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="editable-value-buttons inline-button utility-button"
                        variant="contained"
                        onClick={(e) => {
                            submitEdit(e);
                        }}
                    >
                        {loadingEdit?
                            <div>
                                Sending...
                            </div>
                        :
                            <div>
                                Submit
                            </div>
                        }
                    </Button>
                </div>
            </div>
        )
    }
    if (step === 1) {
        if (loading === false) {
            return (
                <CircularProgress className="centered-content loading-spinner" />
            );

        }
        if (exist === false) {
            return (
                <div className="centered-content">Story not found</div>
            )
        }
        if (story /* && story[0] && story[0].postText */) {
            let counter = 0;
            function setCounter() {
                counter = counter + 1;
            }
           
           
            return (
                <div>
                    <h2>Your personal submission page</h2>
                    <Card className="box-section">
                        <h3>Links</h3>
                        <hr/>
                        <div>
                            <h4>Save your personal submission page:</h4> <a href={url}>{url}</a>
                            <Button
                                className="inline-button utility-button"
                                variant="contained"
                                onClick={() => { navigator.clipboard.writeText(url); handleClick(); }}
                            >
                                Copy
                            </Button>
                            <Snackbar
                                open={open}
                                autoHideDuration={6000}
                                onClose={handleClose}
                                message="Copied to Clipboard"
                            />
                        </div>
                        <div>
                            <h4>Send this link to anyone who you want to read and respond to your story:</h4> <a href={toLink}>{toLink}</a>
                            <Button
                                className="inline-button utility-button"
                                variant="contained"
                                onClick={() => { navigator.clipboard.writeText(toLink); handleClick(); }}
                            >
                                Copy
                            </Button>
                        </div>
                    </Card>
                    <Card className="box-section">

                        <h3>
                            Story
                        </h3>
                        <hr/>
                        <p>
                            {edit ? generateEdit() : generateStory()}
                        </p>

                    </Card>
                    <div>
                        <Card className="box-section">

                            <h3>
                                Responses
                            </h3>
                            <hr/>
                            {response && response[0] ?
                                <div>
                                    {response.map((r, index) => {
                                        return (<div>
                                            <p>
                                                {r}
                                            </p>
                                            {/* Show a divider line under responses, unless it's the last response */}
                                            {index!==response.length-1 &&<Divider />}
                                           {/* <ResponseBox counter = {counter} rUniqueId = {rUniqueId}/> */}
                                           {setCounter()}

                                        </div>)
                                    })}
                                </div>
                                :
                                <div>
                                    <p className="lighter-text">
                                        No responses yet
                                    </p>
                                </div>
                            }
                        </Card>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div>Story not found</div>
            );
        }
    }

}
export default From;
//{replyOpen ? document.getElementById(replyIndex).innerHTML=generateReply() : <div> test </div>}