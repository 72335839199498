import './App.css';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction, Grid } from '@mui/material';
import Home from './pages/Home';
import To from './pages/To';
import ShareStory from './pages/ShareStory';
import From from './pages/From';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import logo from './images/logo.png'

function App() {
  /* <nav>
        <Link to ="/"> Home </Link>
        <Link to ="/sharestory">Share Story</Link>
        </nav>*/
  let navigate = useNavigate();
  //const test = ()=>{
  //navigate("/home");
  //<Router>  <Link to ="/"> Home </Link>
  //</Router>
  //}
  return (
    <Grid>
      <div>
        <BottomNavigation
          showLabels
          className='nav-bar'
        >
          <div className='website-header'>
            <a href='/'>
              <img
                className='website-logo'
                src={logo}
                alt='Heart To Heart logo'
              />
            </a>
            <a href='/' style={{textDecoration:"none"}}>
              <h1 className='website-name'>
                Heart To Heart
              </h1>
            </a>
          </div>
          <BottomNavigationAction
            label="Home"
            sx={{
              '&:hover': {
                textColor: '#acc5f0',
                opacity: [0.9, 0.8, 0.7],
              }
            }}
            onClick={() => navigate("/")}/>
          <BottomNavigationAction
            label="Share My Story"
            sx={{
              '&:hover': {
                textColor: '#acc5f0',
                opacity: [0.9, 0.8, 0.7],
              }
            }}
            onClick={() => navigate("/sharestory")} />
        </BottomNavigation>
        <hr/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sharestory" element={<ShareStory />} />
          <Route path="/to/*" element={<To />} />
          <Route path="/from/*" element={<From />} />
          <Route path="/*" element={<Navigate to='/' />} />
        </Routes>
      </div>
    </Grid>
  );
}

export default App;
