import React from "react";
import { Accordion, AccordionSummary, Button, Card, Divider, Grid, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


function Home(){
    // <Container><Box sx={{ bgcolor: '#cfe8fc', height: '100vh' }}></Box></Container>
    return (
      <Grid>
        <Card className="page-card centered-content">
          <div className="centered-content">
            <h1 className="centered-content page-title">
              Heart To Heart is for helping people understand your perspective
            </h1>
            <div className="centered-content home-content">
              <p>
                When you have a conflict, you can share your story of what happened in a way that other people will listen.
              </p>
              <p>
                And if you’re on the receiving end of that story, you can write back with your own impressions, so that the other person will hear you too.
              </p>


              <h2>
                How it works
              </h2>
              <h4>
                One person will write out their story.
              </h4>
              <Card class="centered-content user-input input-card demo-component">
                <div>
                  <TextField
                    className="story-input"
                    id="outlined-multiline-static"
                    multiline
                    placeholder="Share your perspective of what happened."
                    sx={{backgroundColor:'white'}}
                    rows={5}
                    disabled
                  />
                </div>
                <div class="sbutton">
                  <Button
                    className="submit-button"
                    variant="contained"
                    disabled
                  >
                    Submit
                  </Button>
                </div>
              </Card>
              <h4>
                After saving it, they’ll get a unique link to edit their story and read the responses to it. They’ll also get a second unique link to share with anyone who they want to read and respond to the story.
              </h4>

              <Card className="box-section centered-content demo-component wide-demo-component">
                <div>
                  <h4>Save your personal submission page:</h4> <a style={{textDecoration:'underline'}}>https://www.HeartToHeartProject.org/from/yelping-apricot-iguana</a>
                  <Button
                      className="inline-button utility-button"
                      variant="contained"
                      disabled
                      style={{color:'white'}}
                  >
                      Copy
                  </Button>
                </div>
                  <div>
                    <h4>Send this link to anyone who you want to read and respond to your story:</h4> <a style={{textDecoration:'underline'}}>https://www.HeartToHeartProject.org/to/happy-lavender-fish</a>
                    <Button
                      className="inline-button utility-button"
                      variant="contained"
                      disabled
                      style={{color:'white'}}
                    >
                      Copy
                    </Button>
                  </div>
                </Card>

              <h4>
                With the second unique link, the other person (or people) involved in the conflict can read the story, but with a slight twist: they’ll be prompted to read the story as if it were written by someone they care deeply about.
              </h4>
              <Card className="user-input input-card centered-content demo-component wide-demo-component" style={{fontSize:'1.3rem'}}>
                <div className="user-input-content-section">
                  <h3>
                    Please enter the name of one of your favorite people
                  </h3>
                  <div>
                    <TextField
                      className="one-line-input"
                      id="outlined-basic"
                      value="Morgan Freeman"
                      variant="outlined"
                      disabled
                    />
                  </div>
                </div>
              </Card>
              <Card className="user-input input-card centered-content demo-component wide-demo-component">
                <div className="user-input-content-section">
                  <div>
                    <h4>
                      Thanks! Now hold Morgan Freeman in your mind. When reading the following story, imagine Morgan Freeman wrote it to you.
                    </h4>
                  </div>
                </div>
              </Card>

              <h4>
                After reading it, they'll write out how they would want that person to be treated.
              </h4>
                <Card className="user-input input-card centered-content demo-component wide-demo-component">
                  <div className="user-input-content-section">
                    <h3>
                      How would you want Morgan Freeman to be treated in the situation you just read?
                    </h3>
                    <div className="centered-content">
                    <Accordion className="centered-content story-accordion" disabled>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>
                            You can click here to view the story again
                        </Typography>
                      </AccordionSummary>
                    </Accordion>
                    </div>
                    <div>
                      <TextField
                        className="story-input"
                        id="outlined-multiline-static"
                        multiline
                        placeholder="Your reflections..."
                        rows={5}
                        disabled
                      />
                    </div>
                    <div className="centered-content editable-value-buttons">
                      <Button
                        className="inline-button utility-button"
                        variant="contained"
                        style={{color:'white'}}
                        disabled
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </Card>
              <h4>
                The author of the story can then read those submitted responses.
              </h4>
              <Card className="box-section centered-content demo-component wide-demo-component">
                <h3>
                    Responses
                </h3>
                <hr/>
                <div>
                  <p>
                    I'm sorry they were treated that way. I wish I could tell them that even though they made a mistake if they learn from it and change themselves because of it then they can still be a good person.
                  </p>
                  <Divider />
                  <p>
                    I'm still mad at them but I also feel bad for them.
                  </p>
                </div>
                </Card>
            </div>
          </div>
        </Card>
      </Grid>
    )
}
export default Home;