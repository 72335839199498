import React from "react";
import { useState, useEffect, useCallback } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Card, Snackbar, TextField, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getDoc, doc, updateDoc, arrayUnion, addDoc, collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase-config";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

function To() {
    const [story, setStory] = useState("");
    const [stepNumber, setStepNumber] = useState(0);
    const [name, setName] = useState("");
    const [response, setResponse] = useState("");
    const [uniqueId, setUniqueId] = useState("");
    const [rUniqueId, setRUniqueId] = useState("");
    const [responseSuccess, setResponseSuccess] = useState(false);
    const [responseError, setResponseError] = useState(false);
    const [returning, setReturning] = useState(false);
    const [code, setCode] = useState("");
    const [arrayIndex, setArrayIndex] = useState(0);
    const [open, setOpen] = useState(false);
    const [rCode, setRCode] = useState("");
    const [rResponse, setRResponse] = useState("");
    const [rReply, setRReply] = useState("");
    const [codeOpen, setCodeOpen] = useState(false);
    const [submitOpen, setSubmitOpen] = useState(false);
    const [codeError, setCodeError] = useState(false);
    const [numReply, setNumReply] = useState(0);
    const [check, setCheck] = useState(false);
    const [nameOpen, setNameOpen] = useState(false);
    const [recaptchaVerified, setRecaptchaVerified] = useState(false)
    const [loadingResponseSubmission, setLoadingResponseSubmission] = useState(false)
    const recaptchaVerification = useCallback(() => {
        setRecaptchaVerified(true);
      },
      [setRecaptchaVerified]
    );
    useEffect(() => {
        // Get the current URL
        const url = window.location.href
        // Get the unique ID
        const rUniqueId = url.split('/')[4];
        setRUniqueId(rUniqueId);
        //const r = query(collection(db, "response"), where("uniqueId", "==", uniqueId));

        const getStory = async () => {
            const snap = await getDoc(doc(db, 'responses', rUniqueId))
            if (snap.exists()) {
                console.log(snap.data())
                const data = [];
                data.push(snap.data());
                setStory(data);
                // setRUniqueId(story[0].rUniqueId);


            }
            else {
                console.log("No such document")
            }
        }
        getStory();
        //console.log(story[0].rUniqueId);
        /* const getCode = async () => {
            const rQuerySnapshot = await getDoc(doc(db, 'responses', rUniqueId));
            const rResponse = rQuerySnapshot.data().response
            const rcode = rQuerySnapshot.data().code
            const rReply = rQuerySnapshot.data().reply
            setRResponse(rResponse);
            setRCode(rcode);
            setRReply(rReply);
            //console.log(response);
        }
        getCode(); */

    }, [alert])

    /* function returnUser() {

        console.log(rCode);
        rCode.forEach((i) => {
            if (code === i) {
                setArrayIndex(rCode.indexOf(code));
                setReturning(true);
            }
            else {
                console.log("error");
                setCodeOpen(true);
            }
        })



    } */
    function checkName(){
        if (name===""){
            setNameOpen(true);
        }
        else {
            next();
        }
    }
    if (returning === true) {
        const handleClick = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };
        return (
            <div>
                <h2>Personal Response Page</h2>
                <Box className="box-section" sx={{ bgcolor: '#f0eeee' }}>
                    <div>
                        <h4>Save your current page:</h4> <a href={window.location.href}>{window.location.href}</a>
                        <Button className="inline-button" variant="contained" onClick={() => { navigator.clipboard.writeText(window.location.href); handleClick(); }}
                        >Copy</Button>
                        <Snackbar
                            open={open}
                            autoHideDuration={6000}
                            onClose={handleClose}
                            message="Copied to Clipboard"
                        />
                        {/* <h4>Remember your code: {rCode[arrayIndex]} <Button className="inline-button"variant="contained" onClick={() => { navigator.clipboard.writeText(rCode[arrayIndex]); handleClick(); }}
                        >Copy</Button></h4> */}

                    </div>
                </Box>
                <Box  className="box-section" sx={{ bgcolor: '#f0eeee' }}>
                    <h3>Story</h3>
                    {story[0]?
                        <p>
                            {story[0].postText}
                        </p>
                    :
                        <p className="lighter-text">
                            Sorry, something went wrong. We can't find the original story.
                        </p>
                    }
                </Box>
                <Box className="box-section" sx={{ bgcolor: '#f0eeee' }}>
                    <h3>Your response</h3>
                    {rResponse[arrayIndex]}
                </Box>
                <Box className="box-section" sx={{ bgcolor: '#f0eeee' }}>
                    <h3>Their Reply</h3>
                    {rReply[arrayIndex] === rCode[arrayIndex] ? <div>Haven't replied</div> : rReply[arrayIndex]}

                </Box>

            </div>
        );
    }
    /* if (alert === 1) {
        return (
            <div>
                <Alert className="alerts" severity="success">Success! Thank you for submitting a response.</Alert>
                <h3>Enter your code below to access yoor submission page</h3>
                <TextField
                    sx={{ width: '350px' }}
                    id="outlined-basic" label="code" variant="outlined"
                    onChange={(event) => {
                        setCode(event.target.value)
                    }}
                />
                <Button variant="outlined" onClick={returnUser}>Enter</Button>


            </div>

        );
    } */
    
    const next = () => {
        setStepNumber(stepNumber + 1);
    }
    const previous = () => {
        setStepNumber(stepNumber - 1);
    }

    /* if (stepNumber === 0) {
        return (
            <div>
                <div class="response">

                    <h2>Response</h2>
                    <h3>Send a new response</h3>
                    <Button variant="outlined" onClick={next}>Click Here</Button>
                    <h4>Already submitted a response?</h4>
                    <h4>Enter your code below</h4>
                    <TextField
                        sx={{ width: '350px' }}
                        id="outlined-basic" label="code" variant="outlined"
                        onChange={(event) => {
                            setCode(event.target.value)
                        }}
                    />
                    <Button variant="outlined" onClick={returnUser}>Submit</Button>
                    <Snackbar
                        open={codeOpen}
                        autoHideDuration={6000}
                        message="Error incorrect code"
                    />
                </div>
            </div>
        );
    }
    else */ if (stepNumber === 0) {
        //                                                <Box sx= {{bgcolor: '#cfe8fc', height:'50px'}}>

        return (
            <Card className="user-input input-card">
                <div className="user-input-content-section">
                    <h3>
                        Please enter the name of one of your favorite people
                    </h3>
                    {nameOpen?
                        <Alert className="alerts" severity="error">
                            Make sure to enter a name
                        </Alert>
                    :
                        <div></div>
                    }
                    <div>
                        <TextField
                            className="one-line-input"
                            id="outlined-basic"
                            label="Name of a family member, best friend, etc." variant="outlined"
                            value={name}
                            onChange={(event) => {
                                setName(event.target.value);
                                if (event.target.value) {
                                    setNameOpen(false)
                                }
                            }}
                        />
                    </div>
              
                

                    {/* <Snackbar
                            open={nameOpen}
                            autoHideDuration={6000}
                            message="Make sure to enter a name"
                        />/*}
                    {/* <Button variant="outlined" onClick={previous}>Previous</Button> */}

                    <Button className="centered-content utility-button" variant="contained" onClick={checkName}>
                        Next
                    </Button>


                </div>
            </Card>
        );
    }
    else if (stepNumber === 1) {
        return (
            <Card className="user-input input-card">
                <div className="user-input-content-section">
                    <div>
                        <h4>
                            Thanks! Now hold {name} in your mind. When reading the following story, imagine {name} wrote it to you.
                        </h4>
                    </div>

                    <div className="centered-content editable-value-buttons">
                        <Button className="inline-button" variant="outlined" onClick={previous}>Previous</Button>
                        <Button className="inline-button utility-button" variant="contained" onClick={next}>Next</Button>
                    </div>

                </div>
            </Card>
        );
    }
    else if (stepNumber === 2) {

        return (
            <Card className="user-input input-card">
                <div className="user-input-content-section">
                    <h2>{name}'s Story</h2>
                    {story[0]?
                        <p>
                            {story[0].postText}
                        </p>
                    :
                        <p className="lighter-text">
                            Sorry, something went wrong. We can't find this story.
                        </p>
                    }
                    <div className="centered-content editable-value-buttons">
                        <Button className="inline-button" variant="outlined" onClick={previous}>Previous</Button>
                        <Button className="inline-button utility-button" variant="contained" onClick={next}>Next</Button>
                    </div>

                </div>
            </Card>
        );
    }
    else if (stepNumber === 3) {
        const responseCollectionRef = doc(db, "responses", rUniqueId);
        const reply = async () => {
            setLoadingResponseSubmission(true)
            updateDoc(responseCollectionRef,
                {
                    response: arrayUnion(response),
                    postText: story[0]?.postText,
                    code: arrayUnion(code),
                    reply: arrayUnion(code)
                }
            )
                .then(() => {
                    setLoadingResponseSubmission(false)
                    next()
                })
                .catch(() => {
                    setLoadingResponseSubmission(false)
                    setResponseError(true)
                });

        };
        function checkSubmit() {
            setCodeError(false);
            console.log(rCode);
            /* if (rCode) {
                rCode.forEach((i) => {
                    if (code === i) {
                        console.log("error");
                        setCodeError(true);
                    }

                })
            }
            if (response!==""){
                if (rCode) {
                    rCode.forEach((i) => {
                        if (code === i) {
                            console.log("error");
                            setCodeError(true);
                        }
    
                    })
                }
            } */
            if (response === ""/*  || code === "" */) {
                setSubmitOpen(true);
            }
            else {
                setSubmitOpen(false);
            }

            console.log(codeError);
            if (response !== ""/*  && code !== "" && codeError === false */) {
                reply();
            }

        }

        return (
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>

                <Card className="user-input input-card">
                    <div className="user-input-content-section">
                        <h3>
                            How would you want {name} to be treated in the situation you just read?
                        </h3>
                        <div className="centered-content">
                        <Accordion className="centered-content story-accordion">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>
                                    You can click here to view the story again
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {story[0]?
                                    <p>
                                        {story[0].postText}
                                    </p>
                                :
                                    <p className="lighter-text">
                                        Sorry, something went wrong. We can't find this story.
                                    </p>
                                }
                            </AccordionDetails>
                        </Accordion>
                        </div>

                        {submitOpen &&
                            <Alert className="alerts" severity="error">
                                Make sure to enter a response
                            </Alert>
                        }
                        {responseError &&
                            <Alert className="alerts" severity="error">
                                Sorry, something went wrong submitting your response. Please try again.
                            </Alert>
                        }
                        <div>
                            <TextField
                                className="story-input"
                                id="outlined-multiline-static"
                                multiline
                                placeholder="Your reflections..."
                                rows={20}
                                value={response}
                                onChange={(event) => {
                                    setResponse(event.target.value);
                                    if (event.target.value) {
                                        setSubmitOpen(false)
                                    }
                                }}
                            />
                            {/* <h3>Set a code so you can access your response page later</h3>
                            <TextField
                                sx={{ width: '1340px' }}
                                id="outlined-multiline-static"
                                multiline
                                placeholder="enter code"
                                rows={1}
                                onChange={(event) => {
                                    setCode(event.target.value);
                                }}
                            /> */}
                            {/*<Snackbar
                                open={submitOpen}
                                autoHideDuration={6000}
                                message="Make sure to send a response/create a code"
                        />*/}
                            {/* <Snackbar
                                open={codeError}
                                autoHideDuration={6000}
                                message="Make an unique code"
                            /> */}
                        </div>
                        <GoogleReCaptcha
                            onVerify={recaptchaVerification}
                        />
                        <div className="centered-content editable-value-buttons">
                            <Button className="inline-button" variant="outlined" onClick={previous}>
                                Previous
                            </Button>
                            <Button
                                className="inline-button utility-button"
                                variant="contained"
                                onClick={() => {
                                    checkSubmit();
                                }}
                            >
                                {loadingResponseSubmission?
                                    <div>
                                        Sending...
                                    </div>
                                :
                                    <div>
                                        Submit
                                    </div>
                                }
                                
                            </Button>
                        </div>
                    </div>
                </Card>
            </GoogleReCaptchaProvider>

        )
    }
    else if (stepNumber === 4) {
        return (
            <Card className="user-input input-card">
                <div className="user-input-content-section">
                    <h3>
                        Success! Thank you for taking the time to hear someone else's story and share your own perspective.
                    </h3>
                </div>
            </Card>
        )
    }
}
export default To;